import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const metafinanz = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot metafinanz" />
      <StyledHero img={data.metafinanzBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="metafinanz" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://www.metafinanz.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/metafinanz.png"
                alt="metafinanz Logo"
              />
            </a>
            <h4>Pro Bono Unterstützung durch das metafinanz Digital Adoption Team</h4>
          </header>
          <article className={styles.offerText}>

          <p align="center">
          <a href="https://metafinanz.de/digital-adoption/">
            https://metafinanz.de/digital-adoption/
          </a>
          </p>
            <p>
              In der Corona-Krise zeigt sich die Bedeutung digitaler Tools, um den Fortgang der Geschäftstätigkeit sicherzustellen.
              Dabei hat sich der Einsatz von Kommunikations- und Kollaborationssoftware als unerlässlich herausgestellt: Stichwort Home Office.
              Einige Unternehmen haben Teams, JIRA und Co. zwar schon zuvor intensiv genutzt – den weitaus größeren Teil der Unternehmen stellt der flächendeckende Einsatz jedoch vor große Herausforderungen. 
              Insbesondere die sogenannte „letzte Meile“ zwischen Tool und Mitarbeiter gilt es zu überwinden.
              Doch wie sollen plötzlich alle Mitarbeiter, unabhängig von digitaler Kompetenz und Vorerfahrung, auf ein sicheres Anwendungsniveau gebracht werden?
              Genau hier setzen wir mit unserer Digital Adoption Plattform AppNavi an und trainieren Mitarbeiter direkt dort wo die Herausforderungen entstehen: in der Software.
            </p>
            <p>
            Unsere Angebote beruhen auf einer kurzen und kurzfristigen Unterstützung und können bis 31.07.2020 (letzter Zeitpunkt der Anfrage) angefragt werden. 
            Darüber hinaus beläuft sich der zeitliche Rahmen der Angebotskomponente II auf zwei Monate.
            Der Umfang der Unterstützung ist durch die folgende Beschreibung definiert und begrenzt.
            </p>


            <h4>Angebotskomponente I: Initiale Beratung Digital Adoption (1h)</h4>
            <ul>
              <li>
                Durchführung eines einstündigen initialen Beratungsgespräch zum Thema Digital Adoption
              </li>
              <li>
                Toolbezogene Kurz-Analyse der bestehenden Kommunikations- und Kollaborationslandschaft
              </li>
              <li>
                Mapping und Beratung zu Einsatzmöglichkeiten der metafinanz Digital Adoption Plattform AppNavi (siehe II)
                </li>
            </ul>


            <h4>Angebotskomponente II: Adoption Package Communication and Collaboration</h4>
            <ul>
              <li>
                Integration AppNavi per Extension 
              </li>
              <li>
              Bereitstellung von 3 Lernstrecken („Routen“) in Standard-Kommunikations- und Kollaborationstools (z.B. Microsoft Teams, siehe Video AppNavi Demo Video Microsoft Teams)
              </li>
              <li>
                Kurztraining (1 h) Routen- und Newserstellung für max. zwei Key-User (Erstellung eigener Routen und News)
              </li>
              <li>
                Nutzung von AppNavi im vorab definierten Leistungsumfang für zwei Monate 
              </li>
            </ul>

          </article>
          <footer>
            <a
              href="mailto:kontakt@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    metafinanzBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default metafinanz
